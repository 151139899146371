@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Rubik+Glitch&display=swap');

*{
    font-family: "Mulish", sans-serif;
}

body{
    background-color: #FAFAFA;
}

.navbar {
    top: 3rem;
    transform: translateY(-100%);
}


.navbar.scrolled {
    top: 0;
    transform: translateY(0%);
    transition: transform .5s ease;
}

.swiper-button-prev, .blog-prev{
    background-color: #D22027 !important;
    color: #fff !important;
}
.swiper-button-next, .blog-next{
    background-color: #D22027 !important;
    color: #fff !important;
}

.swiper-button-prev, .swiper-button-next, .blog-next, .blog-prev{
    width: 2.5rem !important;
    height: 2.5rem !important;
    border-radius: 100%;
    transition: all .2s ease-in-out;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.swiper-button-prev::after, .swiper-button-next::after, .blog-next::after, .blog-prev::after{
    content: '' !important;
}

.swiper-pagination {
    bottom: 0 ;
}

.swiper-pagination-bullet{
    width: .75rem !important;
    height: .75rem !important;
    border-radius: 2rem !important;
    background-color: #651618 !important;
    opacity: 50% !important;
    transition: all .3s ease-in-out;
}

.swiper-pagination-bullet-active{
    box-shadow: 0 0 10px 0 #D22027;
    background-color: #D22027 !important;
    opacity: 100% !important;
    width: 3rem !important;
}